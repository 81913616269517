// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".MeetingInfo_container__ovSHV{display:flex;flex-direction:row}.MeetingInfo_container__ovSHV .MeetingInfo_title__3e6Dh{font-size:21px;font-weight:400;color:#000;padding:20px 0px}.MeetingInfo_container__ovSHV .MeetingInfo_content__4JvSF{margin-right:20px;flex:1 1;background-color:#fff}.MeetingInfo_container__ovSHV .MeetingInfo_content__4JvSF .MeetingInfo_lable__JFHXX{margin:15px 20px}.MeetingInfo_container__ovSHV .MeetingInfo_content__4JvSF .MeetingInfo_uploadImgLable__1CEyj{padding:20px 0px}.MeetingInfo_container__ovSHV .MeetingInfo_content__4JvSF .MeetingInfo_submitBox__IgpBY{margin:50px 0px}.MeetingInfo_locationItem__Nzd7W{display:flex;justify-content:space-between}.MeetingInfo_locationItem__Nzd7W .MeetingInfo_district__1YOyS{color:#aaa}\n", "",{"version":3,"sources":["MeetingInfo.scss"],"names":[],"mappings":"AAAA,8BAEI,YAAa,CACb,kBAAmB,CAHvB,wDAKQ,cAAc,CACd,eAAe,CACf,UAAmB,CACnB,gBAAiB,CARzB,0DAWQ,iBAAkB,CAClB,QAAO,CACP,qBAAsB,CAb9B,oFAeY,gBAAiB,CAf7B,6FAkBY,gBAAiB,CAlB7B,wFAqBY,eAAgB,CACnB,iCAOL,YAAa,CACb,6BAA8B,CAFlC,8DAIQ,UAAW","file":"MeetingInfo.scss","sourcesContent":[".container{\r\n\r\n    display: flex;\r\n    flex-direction: row;\r\n    .title{\r\n        font-size:21px;\r\n        font-weight:400;\r\n        color:rgba(0,0,0,1);\r\n        padding: 20px 0px;\r\n    }\r\n    .content{\r\n        margin-right: 20px;\r\n        flex: 1;\r\n        background-color: #fff;\r\n        .lable{\r\n            margin: 15px 20px;\r\n        }\r\n        .uploadImgLable{\r\n            padding: 20px 0px;\r\n        }\r\n        .submitBox{\r\n            margin: 50px 0px;\r\n        }\r\n    }\r\n\r\n}\r\n\r\n\r\n.locationItem{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    .district{\r\n        color: #aaa;\r\n    }\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"container": "MeetingInfo_container__ovSHV",
	"title": "MeetingInfo_title__3e6Dh",
	"content": "MeetingInfo_content__4JvSF",
	"lable": "MeetingInfo_lable__JFHXX",
	"uploadImgLable": "MeetingInfo_uploadImgLable__1CEyj",
	"submitBox": "MeetingInfo_submitBox__IgpBY",
	"locationItem": "MeetingInfo_locationItem__Nzd7W",
	"district": "MeetingInfo_district__1YOyS"
};
module.exports = exports;
