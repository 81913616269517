// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".buy-email_container__2mZOI .buy-email_smsPackageTypes__1POSs{display:grid;grid-gap:10px;grid-template-columns:repeat(auto-fill, 255px)}.buy-email_container__2mZOI .buy-email_submitBtn__bjPHQ{background:#ff784b;border:#ff784b}.buy-email_container__2mZOI .buy-email_submitBtn__bjPHQ:hover{background:#ff6e4b;border:#ff6e4b}\n", "",{"version":3,"sources":["buy-email.scss"],"names":[],"mappings":"AAGA,8DAEI,YAAa,CACb,aAAc,CACd,8CAA+C,CAJnD,wDAQI,kBAXa,CAYb,cAZa,CAGjB,8DAYI,kBAdiB,CAejB,cAfiB","file":"buy-email.scss","sourcesContent":["$orange : #ff784b;\r\n$deepOrange : #ff6e4b;\r\n\r\n.container{\r\n  .smsPackageTypes{\r\n    display: grid;\r\n    grid-gap: 10px;\r\n    grid-template-columns: repeat(auto-fill, 255px);\r\n    //justify-content: space-between;\r\n  }\r\n  .submitBtn{\r\n    background: $orange;\r\n    border: $orange;\r\n  }\r\n  .submitBtn:hover{\r\n    background: $deepOrange;\r\n    border: $deepOrange;\r\n  }\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"container": "buy-email_container__2mZOI",
	"smsPackageTypes": "buy-email_smsPackageTypes__1POSs",
	"submitBtn": "buy-email_submitBtn__bjPHQ"
};
module.exports = exports;
