// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".layout-sider-content_responsive__2UfFx{display:flex;flex-direction:row}.layout-sider-content_responsive__2UfFx .layout-sider-content_left__32tUv{flex:4 1;flex-shrink:0;margin-right:3vw}.layout-sider-content_responsive__2UfFx .layout-sider-content_right__1owd6{flex:25 1;flex-shrink:0}\n", "",{"version":3,"sources":["layout-sider-content.scss"],"names":[],"mappings":"AAAA,wCACE,YAAa,CACb,kBAAmB,CAFrB,0EAII,QAAO,CACP,aAAc,CACd,gBAAiB,CANrB,2EASI,SAAQ,CACR,aAAc","file":"layout-sider-content.scss","sourcesContent":[".responsive{\r\n  display: flex;\r\n  flex-direction: row;\r\n  .left{\r\n    flex: 4;\r\n    flex-shrink: 0;\r\n    margin-right: 3vw;\r\n  }\r\n  .right{\r\n    flex: 25;\r\n    flex-shrink: 0;\r\n  }\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"responsive": "layout-sider-content_responsive__2UfFx",
	"left": "layout-sider-content_left__32tUv",
	"right": "layout-sider-content_right__1owd6"
};
module.exports = exports;
