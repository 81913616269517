// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".edit_container__3uXME .edit_infoBox__2Dv7a{background-color:white}.edit_container__3uXME .edit_radioBox__2vbD3{display:flex;align-items:center}.edit_container__3uXME .edit_form__1WuNj .edit_iconsBox__fhM8k{display:flex;flex-wrap:wrap}.edit_container__3uXME .edit_form__1WuNj .edit_iconsBox__fhM8k>*{padding-right:20px}.edit_container__3uXME .edit_form__1WuNj .edit_submitBox__4IyZA{display:flex}\n", "",{"version":3,"sources":["edit.scss"],"names":[],"mappings":"AAAA,4CAEI,sBAAuB,CAF3B,6CAKI,YAAa,CACb,kBAAmB,CANvB,+DAUM,YAAa,CACb,cAAe,CAXrB,iEAaQ,kBAAmB,CAb3B,gEAiBM,YAAa","file":"edit.scss","sourcesContent":[".container {\r\n  .infoBox {\r\n    background-color: white;\r\n  }\r\n  .radioBox {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n  .form {\r\n    .iconsBox {\r\n      display: flex;\r\n      flex-wrap: wrap;\r\n      > * {\r\n        padding-right: 20px;\r\n      }\r\n    }\r\n    .submitBox {\r\n      display: flex;\r\n    }\r\n  }\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"container": "edit_container__3uXME",
	"infoBox": "edit_infoBox__2Dv7a",
	"radioBox": "edit_radioBox__2vbD3",
	"form": "edit_form__1WuNj",
	"iconsBox": "edit_iconsBox__fhM8k",
	"submitBox": "edit_submitBox__4IyZA"
};
module.exports = exports;
