/**
 * 直播详情
 * Create by liaokai on number0number0-05-number7 11:11:06
 */
import {toPost} from "../../http-utils";

export interface pushItem {
    push_url: string,
    title: string
}

export interface ILiveShow {
    "title": string, //标题
    "live_time": string, //直播时间
    "live_status": string, //直播状态
    "astrict": string, //观看限制
    "apply_form": string, //报名表单
    "poster": string, //海报地址
    "background_img": string, //海报地址
    "show_address": string, //前端连接地址
    "qrcode": string,//二维码地址
    "concurrenceNum": number, //并发数
    "leijiLive": number, //累计观看直播数量
    "leijiPlayback": number, //累计观看回放数量
    "audienceVisit": number, //累计观众人数 访问总数
    "audienceLive": number,//累计观众人数 直播
    "audiencePlayback": number//累计观众人数 回放
    "synopsis": string, //简介
    "push_info": pushItem[],
    "look_address": string,
    "heat_value": number,
    "pc_address": string,
    "is_show_count": boolean, // 显示观看次数开关
    "is_heat": boolean, // 显示观看次数开关
    "is_show_online": boolean, // 显示在线人数开关
    "give_like": boolean // 显示在线人数开关
    "is_jing":number,//敬请期待
}

export const liveShow = toPost<
    {
        id : string
    },
    ILiveShow
>("/merchant/live/liveShow");
