// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".new-venue_newMeetingBtnBox__3sVGb{display:flex;justify-content:space-around;margin-top:20px}\n", "",{"version":3,"sources":["new-venue.scss"],"names":[],"mappings":"AAAA,mCACE,YAAa,CACb,4BAA6B,CAC7B,eAAgB","file":"new-venue.scss","sourcesContent":[".newMeetingBtnBox{\r\n  display: flex;\r\n  justify-content: space-around;\r\n  margin-top: 20px;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"newMeetingBtnBox": "new-venue_newMeetingBtnBox__3sVGb"
};
module.exports = exports;
