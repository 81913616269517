// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".title-content-table_table__1r-V_{display:grid;grid-template-columns:auto 1fr;align-items:center}\n", "",{"version":3,"sources":["title-content-table.scss"],"names":[],"mappings":"AAAA,kCACE,YAAa,CACb,8BAA+B,CAC/B,kBAAmB","file":"title-content-table.scss","sourcesContent":[".table{\r\n  display: grid;\r\n  grid-template-columns: auto 1fr;\r\n  align-items: center;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"table": "title-content-table_table__1r-V_"
};
module.exports = exports;
