// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".header-icon_imgBox__2RumE{width:56px;height:56px;padding:10px;border-radius:28px;overflow:hidden;display:flex;justify-content:center;align-items:center;background-size:cover;background-position:center center;background-repeat:no-repeat}.header-icon_imgBox__2RumE .header-icon_img__1cVIp{max-width:100%;max-height:100%}\n", "",{"version":3,"sources":["header-icon.scss"],"names":[],"mappings":"AAAA,2BACE,UAAW,CACX,WAAY,CACZ,YAAa,CACb,kBAAmB,CACnB,eAAgB,CAChB,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CAEnB,qBAAsB,CACtB,iCAAkC,CAClC,2BAA4B,CAZ9B,mDAcI,cAAe,CACf,eAAgB","file":"header-icon.scss","sourcesContent":[".imgBox {\r\n  width: 56px;\r\n  height: 56px;\r\n  padding: 10px;\r\n  border-radius: 28px;\r\n  overflow: hidden;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  //border: #bbb 1px solid;\r\n  background-size: cover;\r\n  background-position: center center;\r\n  background-repeat: no-repeat;\r\n  .img {\r\n    max-width: 100%;\r\n    max-height: 100%;\r\n  }\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"imgBox": "header-icon_imgBox__2RumE",
	"img": "header-icon_img__1cVIp"
};
module.exports = exports;
