// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".signin-view_container__3I--4 .signin-view_header__2EILq{display:flex;justify-content:flex-end;margin-bottom:20px}.signin-view_container__3I--4 .signin-view_grid__2Pz6k{display:grid;grid-template-columns:repeat(4, 1fr);grid-gap:1.8vw}\n", "",{"version":3,"sources":["signin-view.scss"],"names":[],"mappings":"AAAA,yDAEI,YAAa,CACb,wBAAyB,CACzB,kBAAmB,CAJvB,uDAOI,YAAa,CACb,oCAAqC,CACrC,cAAe","file":"signin-view.scss","sourcesContent":[".container{\r\n  .header{\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    margin-bottom: 20px;\r\n  }\r\n  .grid{\r\n    display: grid;\r\n    grid-template-columns: repeat(4, 1fr);\r\n    grid-gap: 1.8vw;\r\n  }\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"container": "signin-view_container__3I--4",
	"header": "signin-view_header__2EILq",
	"grid": "signin-view_grid__2Pz6k"
};
module.exports = exports;
