// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".form-table-local_row__1mFxb td{cursor:default}\n", "",{"version":3,"sources":["form-table-local.scss"],"names":[],"mappings":"AAAA,gCACE,cAAe","file":"form-table-local.scss","sourcesContent":[".row td {\r\n  cursor: default;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"row": "form-table-local_row__1mFxb"
};
module.exports = exports;
