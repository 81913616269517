// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".prepaid-money_payItems__2Rs7p{display:grid;grid-template-columns:repeat(auto-fill, 250px);grid-auto-rows:120px;grid-gap:10px}.prepaid-money_payItems__2Rs7p>*:last-child{grid-column:2 span}.prepaid-money_payMethod__127Cv{display:grid;grid-template-columns:repeat(auto-fill, 250px);grid-template-rows:120px;grid-gap:10px}.prepaid-money_submitBtn__gnIlO{background:#ff784b;border:#ff784b}.prepaid-money_submitBtn__gnIlO:hover{background:#ff6e4b;border:#ff6e4b}\n", "",{"version":3,"sources":["prepaid-money.scss"],"names":[],"mappings":"AAAA,+BACE,YAAa,CACb,8CAA+C,CAC/C,oBAAsB,CACtB,aAAe,CAChB,4CAIC,kBAAmB,CACpB,gCAGC,YAAa,CACb,8CAA+C,CAC/C,wBAA0B,CAC1B,aAAe,CAChB,gCAMC,kBAJe,CAKf,cALe,CAMhB,sCAEC,kBAPmB,CAQnB,cARmB","file":"prepaid-money.scss","sourcesContent":[".payItems{\r\n  display: grid;\r\n  grid-template-columns: repeat(auto-fill, 250px);\r\n  grid-auto-rows : 120px;\r\n  grid-gap : 10px;\r\n}\r\n\r\n.payItems>*:last-child{\r\n  //grid-row: 1 span;\r\n  grid-column: 2 span;\r\n}\r\n\r\n.payMethod{\r\n  display: grid;\r\n  grid-template-columns: repeat(auto-fill, 250px);\r\n  grid-template-rows : 120px;\r\n  grid-gap : 10px;\r\n}\r\n\r\n$orange : #ff784b;\r\n$deepOrange : #ff6e4b;\r\n\r\n.submitBtn{\r\n  background: $orange;\r\n  border: $orange;\r\n}\r\n.submitBtn:hover{\r\n  background: $deepOrange;\r\n  border: $deepOrange;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"payItems": "prepaid-money_payItems__2Rs7p",
	"payMethod": "prepaid-money_payMethod__127Cv",
	"submitBtn": "prepaid-money_submitBtn__gnIlO"
};
module.exports = exports;
