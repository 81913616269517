// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ParticleFlowBox_ParticleFlowBox__ziTHI{width:100%;height:100%;-webkit-filter:blur(0px);filter:blur(0px);position:absolute;top:50%;left:50%;transform:translateX(-50%) translateY(-50%);z-index:0}@-webkit-keyframes ParticleFlowBox_bluranmi__1oFbC{0%{-webkit-filter:blur(2px);filter:blur(2px)}50%{-webkit-filter:blur(3px);filter:blur(3px)}100%{-webkit-filter:blur(2px);filter:blur(2px)}}@keyframes ParticleFlowBox_bluranmi__1oFbC{0%{-webkit-filter:blur(2px);filter:blur(2px)}50%{-webkit-filter:blur(3px);filter:blur(3px)}100%{-webkit-filter:blur(2px);filter:blur(2px)}}\n", "",{"version":3,"sources":["ParticleFlowBox.scss"],"names":[],"mappings":"AAAA,wCACI,UAAW,CACX,WAAY,CACZ,wBAAiB,CAAjB,gBAAiB,CAEjB,iBAAkB,CAClB,OAAQ,CACR,QAAS,CACT,2CAA4C,CAC5C,SAAU,CACb,mDAEG,GACI,wBAAA,CAAA,gBAAiB,CAErB,IACI,wBAAA,CAAA,gBAAiB,CAErB,KACI,wBAAA,CAAA,gBAAiB,CAAA,CATxB,2CAEG,GACI,wBAAA,CAAA,gBAAiB,CAErB,IACI,wBAAA,CAAA,gBAAiB,CAErB,KACI,wBAAA,CAAA,gBAAiB,CAAA","file":"ParticleFlowBox.scss","sourcesContent":[".ParticleFlowBox{\r\n    width: 100%;\r\n    height: 100%;\r\n    filter: blur(0px);\r\n    // animation: bluranmi 2s infinite;\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translateX(-50%) translateY(-50%);\r\n    z-index: 0;\r\n}\r\n@keyframes bluranmi{\r\n    0%{\r\n        filter: blur(2px);\r\n    }\r\n    50%{\r\n        filter: blur(3px);\r\n    }\r\n    100%{\r\n        filter: blur(2px);\r\n    }\r\n}"]}]);
// Exports
exports.locals = {
	"ParticleFlowBox": "ParticleFlowBox_ParticleFlowBox__ziTHI",
	"bluranmi": "ParticleFlowBox_bluranmi__1oFbC"
};
module.exports = exports;
