// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".iphone-simulator_container__22rjY{position:relative}.iphone-simulator_container__22rjY .iphone-simulator_iphoneBorderBox__3y7zJ{max-width:100%;max-height:100%;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-user-drag:none;z-index:4}.iphone-simulator_container__22rjY .iphone-simulator_content__12KlX{display:flex;flex-direction:column;overflow-y:auto;position:absolute;left:0px;right:0px;top:0px;bottom:0px;z-index:3}\n", "",{"version":3,"sources":["iphone-simulator.scss"],"names":[],"mappings":"AAAA,mCACE,iBAAkB,CADpB,4EAGI,cAAe,CACf,eAAgB,CAChB,wBAAiB,CAAjB,qBAAiB,CAAjB,oBAAiB,CAAjB,gBAAiB,CACjB,sBAAuB,CACvB,SAAU,CAPd,oEAUI,YAAa,CACb,qBAAsB,CACtB,eAAgB,CAEhB,iBAAkB,CAClB,QAAS,CACT,SAAU,CACV,OAAQ,CACR,UAAW,CACX,SAAU","file":"iphone-simulator.scss","sourcesContent":[".container{\r\n  position: relative;\r\n  .iphoneBorderBox{\r\n    max-width: 100%;\r\n    max-height: 100%;\r\n    user-select: none;\r\n    -webkit-user-drag: none;\r\n    z-index: 4;\r\n  }\r\n  .content{\r\n    display: flex;\r\n    flex-direction: column;\r\n    overflow-y: auto;\r\n\r\n    position: absolute;\r\n    left: 0px;\r\n    right: 0px;\r\n    top: 0px;\r\n    bottom: 0px;\r\n    z-index: 3;\r\n  }\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"container": "iphone-simulator_container__22rjY",
	"iphoneBorderBox": "iphone-simulator_iphoneBorderBox__3y7zJ",
	"content": "iphone-simulator_content__12KlX"
};
module.exports = exports;
