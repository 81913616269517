// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".DarkBox_DarkBox__3MR8Q{width:100%;height:100%;position:fixed;top:0px;left:0px;background:rgba(0,0,0,0.6);z-index:999;opacity:0;-webkit-animation:DarkBox_ScaleIn__1b8YU 0.5s forwards;animation:DarkBox_ScaleIn__1b8YU 0.5s forwards}@-webkit-keyframes DarkBox_ScaleIn__1b8YU{to{opacity:1}}@keyframes DarkBox_ScaleIn__1b8YU{to{opacity:1}}\n", "",{"version":3,"sources":["DarkBox.scss"],"names":[],"mappings":"AAAA,wBACI,UAAW,CACX,WAAY,CACZ,cAAe,CACf,OAAQ,CACR,QAAS,CACT,0BAAgC,CAChC,WAAY,CACZ,SAAU,CACV,sDAAA,CAAA,8CAAgC,CACnC,0CAEG,GACI,SAAU,CAAA,CAHjB,kCAEG,GACI,SAAU,CAAA","file":"DarkBox.scss","sourcesContent":[".DarkBox{\r\n    width: 100%;\r\n    height: 100%;\r\n    position: fixed;\r\n    top: 0px;\r\n    left: 0px;\r\n    background: rgba($color: #000000, $alpha: 0.6);\r\n    z-index: 999;\r\n    opacity: 0;\r\n    animation: ScaleIn 0.5s forwards;\r\n}\r\n@keyframes ScaleIn{\r\n    to{\r\n        opacity: 1;\r\n    }\r\n}"]}]);
// Exports
exports.locals = {
	"DarkBox": "DarkBox_DarkBox__3MR8Q",
	"ScaleIn": "DarkBox_ScaleIn__1b8YU"
};
module.exports = exports;
