// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".form-preview_container__3gz1B .form-preview_tips__3zNjp{font-size:10px}.form-preview_container__3gz1B .form-preview_BaseTitle__12R5M{font-size:12px;font-weight:500;color:#006fce;box-shadow:rgba(0,111,206,0.15) 0 -8px 0px -2px inset}.form-preview_container__3gz1B .form-preview_BaseTitleBox__3sVWw{display:flex;margin-top:5px}.form-preview_container__3gz1B .form-preview_submitBox__3xUHS{display:flex;justify-content:center;margin:30px 0px}\n", "",{"version":3,"sources":["form-preview.scss"],"names":[],"mappings":"AAAA,yDAEI,cAAe,CAFnB,8DAKI,cAAc,CACd,eAAe,CACf,aAAuB,CACvB,qDAAqE,CARzE,iEAWI,YAAa,CACb,cAAe,CAZnB,8DAgBI,YAAa,CACb,sBAAuB,CACvB,eAAgB","file":"form-preview.scss","sourcesContent":[".container{\r\n  .tips{\r\n    font-size: 10px;\r\n  }\r\n  .BaseTitle{\r\n    font-size:12px;\r\n    font-weight:500;\r\n    color:rgba(0,111,206,1);\r\n    box-shadow: rgba($color: #006FCE, $alpha: 0.15) 0 -8px 0px -2px inset;\r\n  }\r\n  .BaseTitleBox{\r\n    display: flex;\r\n    margin-top: 5px;\r\n  }\r\n\r\n  .submitBox{\r\n    display: flex;\r\n    justify-content: center;\r\n    margin: 30px 0px;\r\n  }\r\n\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"container": "form-preview_container__3gz1B",
	"tips": "form-preview_tips__3zNjp",
	"BaseTitle": "form-preview_BaseTitle__12R5M",
	"BaseTitleBox": "form-preview_BaseTitleBox__3sVWw",
	"submitBox": "form-preview_submitBox__3xUHS"
};
module.exports = exports;
