/**
 * 直播列表
 * Create by liaokai on 2020-05-25 10:28:20
 */
import * as React from 'react';
import {Component} from "react";
import styled from "styled-components";
import {MetaCard} from "../../../../../components/meta-card";
import {FlexCol} from "../../../../../components/style/flex/flex-col";
import {Texts} from "../../../../../components/text/Texts";
import {Divider, Empty, Input, Pagination, Popconfirm, Select, Spin,DatePicker } from "antd";
import {Air} from "../../../../../components/air/Air";
import {Link} from "react-router-dom";
import {Flex} from "../../../../../components/style/flex/flex";
import {IRecentlyLiveItem, recentlyLive} from "../../../../../common/apis/apis/live/recentlyLive";
import {liveCopyStore} from "../../../../../common/apis/apis/live/liveCopyStore";
import {FlexJustifyEnd} from "../../../../../components/style/flex/flex-justify-end";
import {FlexJustifyBetween} from "../../../../../components/style/flex/flex-justify-between";
import Button from "antd/es/button";
import {DeleteOutlined, EditOutlined, RollbackOutlined} from "@ant-design/icons/lib";
import {Display} from "../../../../../components/display/display";
import {CheckBoxHigherOrder} from "../../../../../components/check-box-higher-order/check-box-higher-order";
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import { Moment } from "moment"
import moment from "moment";
const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

interface IProps{
    
}

interface IState{
    listData : IRecentlyLiveItem[],
    pageSize : number,
    total : number,
    currentPage : number,
    loading : boolean,
    editing : boolean,
    searchText : string,
    meetingTime?: [Moment, Moment],          //会议时间, 固定长度 length = 2，[开始时间，结束时间]
    title:string,
    
}

export class LiveListComp extends Component<IProps,IState>{
    selectedMeeting : IRecentlyLiveItem[] = []

    readonly state : IState = {
        listData : [],
        pageSize : 12,
        total : 0,
        currentPage : 1,
        loading : false,
        editing : false,
        searchText : "",
        title:''
    };

    componentDidMount() {
        this.getListData();
    }

    getListData = (page: number = 1) => {
        this.setState({loading : true})
        console.log(this.state.title,this.state.meetingTime)
       
        let live_time_begin = this.state.meetingTime?.[0].format("YYYY/MM/DD HH:mm:ss")
        let live_time_end   = this.state.meetingTime?.[1].format("YYYY/MM/DD HH:mm:ss")
        
        recentlyLive({
            page : page,
            paginate : this.state.pageSize,
            live_time_begin:live_time_begin,
            live_time_end:live_time_end,
            title:this.state.title
        }).then(res => {
            this.setState({
                listData : res.data,
                currentPage : page,
                total : res.total
            })
        }).finally(()=>{
            this.setState({loading : false})
        })
    }
    getcopy(live_id:any){
        //<Prompt when={true} message="Are you sure you want to leave?" />

         console.log(live_id)  
         this.setState({loading : true})
            liveCopyStore({
                copy_live_id:live_id,
            }).then(res => {
                this.getListData()
            }).finally(()=>{
                this.setState({loading : false})
            })

    }
    getBackColors(status: string) {
        let backColor = '';
        switch (status) {
            case '预告':
                backColor = 'blue';
                break;
            case '直播中':
                backColor = 'red';
                break;
            case '回放':
                backColor = 'green';
                break;
            case '结束':
                backColor = 'grey';
                break;
            default:
                backColor = 'blue';
        }
        // console.log(backColor);
        return backColor;
    }

    refreshPage = ()=>{
        this.getListData(this.state.currentPage)
    }
    render() {
        // let {} = this.props;
        let { listData, pageSize, currentPage, total, loading, editing,meetingTime,title} = this.state;
        console.log(listData);
        return (
            <div>
                <div style={{ marginBottom: 20,display:'flex',justifyContent:'flex-end' }}>
                    <RangePicker  value={meetingTime} locale={locale} onChange={(values, formatString) => {
                        // @ts-ignore
                        this.setState({ meetingTime: values })
                    }} />
                    <Search
                    placeholder="请输入名称"
                 
                    style={{ width: 200 }}
                    onSearch={(value) => {
                        this.setState({ title: value },()=>{
                            this.getListData();
                        });
                    }}
                    />
                </div> 
                {/*/!*操作*!/*/}
                {/*<FlexJustifyBetween>*/}
                {/*    /!*编辑*!/*/}
                {/*    <Flex>*/}
                {/*        {*/}
                {/*            editing*/}
                {/*                ?*/}
                {/*                [*/}
                {/*                    <div>*/}
                {/*                        <Popconfirm disabled={listData?.length <= 0} placement="bottomLeft" title={"确定要删除选中的项目吗？"} onConfirm={async ()=>{*/}
                {/*                            alert(JSON.stringify(this.selectedMeeting));*/}
                {/*                            // try {*/}
                {/*                            //     await delMeeting({meetid : this.selectedMeeting.map(value => value.id)});*/}
                {/*                            //     message.success("删除完成")*/}
                {/*                            // }catch (e) {*/}
                {/*                            //     message.error("删除失败")*/}
                {/*                            // }finally {*/}
                {/*                            //     this.updateListData();*/}
                {/*                            //     this.setState({editing : false})*/}
                {/*                            // }*/}
                {/*                        }} okText="删除" cancelText="取消">*/}
                {/*                            <Button danger={true}><DeleteOutlined/> 删除</Button>*/}
                {/*                        </Popconfirm>*/}
                {/*                    </div>*/}
                {/*                    ,*/}
                {/*                    <Air horizontal={5}/>*/}
                {/*                    ,*/}
                {/*                    <Button icon={<RollbackOutlined/>} onClick={()=>{*/}
                {/*                        this.setState({editing : false})*/}
                {/*                        this.selectedMeeting = []*/}
                {/*                    }}>取消</Button>*/}
                {/*                ]*/}
                {/*                :*/}
                {/*                <Button type="primary" icon={<EditOutlined/>} onClick={()=>{*/}
                {/*                    this.setState({editing : true})*/}
                {/*                }}>编辑</Button>*/}
                {/*        }*/}
                {/*        /!*<Air horizontal={5}/>*!/*/}
                {/*        /!*<div>*!/*/}
                {/*        /!*    <Display show={!editing} justHidden={true}>*!/*/}
                {/*        /!*        <SelectBoxCustom*!/*/}
                {/*        /!*            data={[*!/*/}
                {/*        /!*                {value:'start_time',show:'按照会议时间排序'},*!/*/}
                {/*        /!*                {value:'create_time',show:'按照创建时间排序'}*!/*/}
                {/*        /!*            ]}*!/*/}
                {/*        /!*            onSelect={(item : {value : tMeetingListSort, show : string})=>{*!/*/}
                {/*        /!*                this.setState({sort : item.value},this.updateListData);*!/*/}
                {/*        /!*            }}*!/*/}
                {/*        /!*        />*!/*/}
                {/*        /!*    </Display>*!/*/}
                {/*        /!*</div>*!/*/}
                {/*        /!*<Air horizontal={5}/>*!/*/}
                {/*        /!*<div>*!/*/}
                {/*        /!*    <Display show={!editing} justHidden={true}>*!/*/}
                {/*        /!*        <Select defaultValue={eMeetingListSearchRange.all} onChange={value=>{*!/*/}
                {/*        /!*            this.setState({searchRange : value},this.updateListData);*!/*/}
                {/*        /!*        }}>*!/*/}
                {/*        /!*            <Option value={eMeetingListSearchRange.all}>全部</Option>*!/*/}
                {/*        /!*            <Option value={eMeetingListSearchRange.preview}>预告</Option>*!/*/}
                {/*        /!*            <Option value={eMeetingListSearchRange.ing}>进行</Option>*!/*/}
                {/*        /!*            <Option value={eMeetingListSearchRange.over}>结束</Option>*!/*/}
                {/*        /!*        </Select>*!/*/}
                {/*        /!*    </Display>*!/*/}
                {/*        /!*</div>*!/*/}
                {/*    </Flex>*/}
                {/*    /!*搜索*!/*/}
                {/*    <div>*/}
                {/*        <Display show={!editing} justHidden={true}>*/}
                {/*            <Search placeholder={"搜索会议、地点"}*/}
                {/*                    onSearch={(text)=>{*/}
                {/*                        this.setState({searchText : text}, ()=>{*/}
                {/*                            this.refreshPage()*/}
                {/*                        });*/}
                {/*                    }}>*/}
                {/*            </Search>*/}
                {/*        </Display>*/}
                {/*    </div>*/}
                {/*</FlexJustifyBetween>*/}
                {/*<Air vertical={8}/>*/}
                <Spin spinning={loading}>
                    {
                        listData.length ?
                            <Grid>
                                {
                                    editing ?
                                        <CheckBoxHigherOrder
                                            data={listData}
                                            itemStyle={{display : "flex",flexDirection: "column"}}
                                            renderUnSelectItem={value =>
                                                <MetaCard
                                                    title={value.title}
                                                    time={value.time}
                                                    count={<div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <span>观众人数:{value.people_num}人</span>
                                                        <span>观看次数:{value.look_num}次</span>
                                                        </div>}
                                                    cover={value.poster}
                                                    select={"unselect"}
                                                />
                                            }
                                            renderSelectedItem={value =>
                                                <MetaCard
                                                    title={value.title}
                                                    time={value.time}
                                                    count={<div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <span>观众人数:{value.people_num}人</span>
                                                        <span>观看次数:{value.look_num}次</span>
                                                        </div>}
                                                    cover={value.poster}
                                                    select={"selected"}
                                                />                                           }
                                            onChange={items => {
                                                this.selectedMeeting = items;
                                            }}
                                            extractKey={item => item.live_id.toString()}
                                        >
                                        </CheckBoxHigherOrder>
                                        :
                                        listData.map(value => (
                                          
                                            <div style={{position: 'relative'}} key={`live/${value.live_id}`}>
                                                <FlexCol>
                                                    <LiveStatus color={this.getBackColors(value.live_status)}>{value.live_status}</LiveStatus>
                                                    <div style={{"position": "absolute","padding":"0 10px","width": "100%","height": "22px","display": "flex","justifyContent": "flex-end","zIndex": 1,}}>
                                                    {value.is_register?<LiveType style={{marginRight:'10px'}} color={'gray'}>表单</LiveType>:null}
                                                    <LiveType color={'gray'}>{value.type_to_str}</LiveType>
                                                    </div>
                                                   
                                                    <MetaCard
                                                        title={value.title}
                                                        time={value.time}
                                                        count={<div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <span>观众人数:{value.people_num}人</span>
                                                            <span>观看次数:{value.look_num}次</span>
                                                            </div>}
                                                        cover={value.poster}
                                                        
                                                        actionView={
                                                           
                                                            <HoverItem>
                                                                <Link className={"link"}  to={`live/${value.live_id}`} target="_blank">
                                                                    <div className={"textBox"}>
                                                                        内容
                                                                    </div>
                                                                </Link>
                                                                <Link className={"link"}  to={`live/${value.live_id}/menu/edit`} target="_blank">
                                                                    <div className={"textBox"}>
                                                                        设置
                                                                    </div>
                                                                </Link>
                                                                <div className={"link"} onClick={()=>{
                                                                        
                                                                }} >
                                                                      <Popconfirm placement="top" title={"确认复制这个会议吗？"} onConfirm={async ()=>{
                                                                            this.getcopy(value.live_id)
                                                                        }} okText="确定" cancelText="取消">
                                                                          <div className={"textBox"}>
                                                                            一键复制
                                                                            </div>
                                                                        </Popconfirm>
                                                                         
                                                                </div>
                                                                {/* <Link className={"link"} target="_blank" to={`live/${value.live_id}/live_room`}>
                                                                    <div className={"textBox"}>
                                                                        开播
                                                                    </div>
                                                                </Link> */}
                                                                <Link className={"link"}  to={`live/${value.live_id}/menu/data_count`} target="_blank">
                                                                    <div className={"textBox"}>
                                                                        数据
                                                                    </div>
                                                                </Link>
                                                            </HoverItem>
                                                           
                                                        }
                                                    /> 
                                                    
                                                </FlexCol>
                                            </div>
                                        
                                        ))
                                }
                            </Grid>
                            :
                            <Empty description={"暂无数据"}/>
                    }
                </Spin>
                <Air vertical={10}/>
                {
                    listData.length ?
                        <FlexJustifyEnd>
                            <Pagination pageSize={pageSize} current={currentPage} total={total} onChange={page => {
                                this.getListData(page)
                            }}/>
                        </FlexJustifyEnd>
                        :
                        undefined
                }
            </div>
        );
    }
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.8vw;
`

const HoverItem = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;

    & .link{
      color: #fff;
      display: inline-block;
      text-align: center;
      height: 100%;
    }
    
    & .link .textBox{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    & .link :hover {
        background: #3b87f7;
    }
`
const LiveStatus = styled.span`
  position: absolute;
  top: 0;
  left: 10px;
  display: inline-block;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 0 0 5px 5px;
  color: white;
  background: ${props => props.color};
  z-index: 9;
`;
const LiveType = styled.span`
  display: inline-block;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 0 0 5px 5px;
  color: white;
  background: ${props => props.color};
`;