// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".RichTextBox_RichTextBox__RycNE{width:100%;height:100%}\n", "",{"version":3,"sources":["RichTextBox.scss"],"names":[],"mappings":"AAAA,gCACI,UAAW,CACX,WAAY","file":"RichTextBox.scss","sourcesContent":[".RichTextBox{\r\n    width: 100%;\r\n    height: 100%;\r\n}"]}]);
// Exports
exports.locals = {
	"RichTextBox": "RichTextBox_RichTextBox__RycNE"
};
module.exports = exports;
