// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index_container__AAPqY{width:100%}\n", "",{"version":3,"sources":["index.scss"],"names":[],"mappings":"AAAA,wBACE,UAAW","file":"index.scss","sourcesContent":[".container{\r\n  width: 100%;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"container": "index_container__AAPqY"
};
module.exports = exports;
